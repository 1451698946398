import VueGtag from 'vue-gtag';
import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './styles/style.scss';

createApp(App).use(store).use(router).use(VueGtag, {
  config: { id: 'G-1V9W01NCGT' },
})
  .mount('#app');
