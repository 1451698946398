<template>
  <div class="home">
    <section class="hero-container">
      <img class="mobile" src="/img/mobile_hero.png" alt="mobile_hero" />
      <img class="desktop" src="/img/desktop_hero.png" alt="desktop_hero" />
    </section>
    <section class="main-content">
      <div class="logo-container">
        <img src="/img/aperol_logo.svg" alt="aperol_logo" />
      </div>
      <p class="first">
        <span
          >Thank you for participating in the
          <br />
          Aperol Spritz Trivia Game.
        </span>
        <span>you did great!</span>
      </p>
      <p class="second">
        <span
          >ENJOY THIS BUBBLING OFFER FOR
          <br class="showInDesktop">
           FREE SHIPPING ON YOUR NEXT APEROL ORDER.</span
        >
      </p>
      <p class="third">
        Use code
        <span class="code">APEROLFESTIVAL</span> during checkout through 12/31/24. Must be 21+.
      </p>
      <a
        class="btn"
        target="_blank"
        @click="track"
        href="https://www.reservebar.com/collections/aperol?utm_source=website&utm_medium=crm&utm_campaign=festival"
        >ORDER HERE</a
      >
      <div class="line"></div>
      <p class="terms">
        Must be 21+. Expires on 12/31/24 at 11:59 EDT.
        <br /><br />
        Aperol® Liqueur. 11% alc./vol. (22 Proof). <br />
        ©2024 Campari America, New York, NY. Please enjoy responsibly.
      </p>
    </section>
  </div>
</template>

<script>
// import axios from 'axios';

import API from '../../utils/api';

export default {
  name: 'HomeView',
  components: {},
  data() {
    return {};
  },
  methods: {
    async testAPI() {
      console.log('Testing API...');
      const { data } = await API.get('/test');
      console.log(data);
    },
    async log(message) {
      console.log('Testing API DB...');
      const payload = {
        interaction: message,
      };
      const { data } = await API.post('/log', payload);
      console.log(data);
    },
    track() {
      console.log(process.env.VUE_APP_PRUEBA);
      this.$gtag.event('btn_here_click', {
        event_category: 'click',
        event_label: 'btn_here_click',
        value: 0,
      });
    },
  },
  async beforeMount() {
    // this.testAPI();
    // this.log('Loaded application...');
  },
};
</script>

<style lang="scss">
$fontBold: "Futura Std Bold";
$fontBoldCondensed: "Futura Std Bold Condensed";
$fontLight: "Futura Std Light";
$fontMedium: "Futura Std Medium Condensed", sans-serif;

.home {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  flex-wrap: wrap;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    section {
      width: 50%;
    }
  }

  .hero-container {
    .desktop {
      display: none;
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media screen and (min-width: 600px) {
        & {
          display: flex;
        }
      }
    }
    .mobile {
      display: flex;
      width: 100%;
      @media screen and (min-width: 600px) {
        & {
          display: none;
        }
      }
    }
  }

  .main-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 15px;
    align-items: center;
    text-align: center;

    @media screen and (min-width: 600px) {
      padding: 50px 70px;
    }

    .logo-container {
      margin: 25px 0;
    }

    p.first {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      /* or 25px */

      text-align: center;
      text-transform: uppercase;
      font-family: $fontMedium;
      span {
        font-family: $fontBoldCondensed;
        font-weight: 700;
      }

      @media screen and (min-width: 600px) {
        font-size: 30px;
        line-height: 36px;
        margin: 30px 0 56px 0;
      }
    }

    p.second {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 140%;
      margin: 0px;
      text-align: center;
      text-transform: uppercase;
      font-family: $fontMedium;
      span {
        font-family: $fontBoldCondensed;
        font-weight: 700;
      }

      @media screen and (min-width: 600px) {
        font-size: 50px;
        line-height: 60px;
      }

      .showInDesktop {
        display: block;

        @media screen and (max-width: 767px) {
          display: none;
        }

      }
    }

    img.image {
      width: 230px;

      @media screen and (min-width: 600px) {
        width: 417px;
      }
    }
    p.pre-second {
      font-size: 52px;
      font-family: "Futura Std Bold Condensed";
      font-style: normal;
      font-weight: 700;
      font-size: 46px;
      line-height: 108.5%;
      text-align: center;
      text-transform: uppercase;
      @media screen and (max-width: 600px) {
        font-size: 35px;
        line-height: 105.5%;
      }
      sup {
        font-size: 15px;
        position: relative;
        top: -10px;
      }
    }

    p.third {
      font-weight: 500;
      font-size: 18px;
      font-family: $fontLight;
      padding: 0 50px;

      @media screen and (min-width: 600px) {
        font-size: 20px;
        line-height: 140%;
        margin: 50px 0 20px 0;
      }

      span.code {
        background-color: rgba(255, 255, 255, 0.43);
        font-family: $fontLight;
        letter-spacing: 3px;

        @media screen and (min-width: 600px) {
          font-size: 20px;
          line-height: 140%;
        }
      }
    }

    a.btn {
      width: 278px;
      height: 46px;
      background-color: #ffffff;
      box-shadow: 0px 4.27006px 8.54011px rgba(183, 53, 2, 0.88);
      border: none;
      border-radius: 37.1584px;
      margin: 10px 0 33px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fa4700;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      font-family: $fontBold;
      text-decoration: none;

      @media screen and (min-width: 600px) {
        width: 278px;
        height: 50px;
        margin: 21px 0 41px 0;
        transition: all 125ms;
        font-size: 20px;
        line-height: 27px;
      }

      &:hover {
        transition: all 125ms;
        cursor: pointer;
        opacity: 0.8;
      }

      &:active {
        transition: all 125ms;
        cursor: pointer;
        opacity: 0.8;
      }
    }

    div.line {
      width: 70px;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.43);
      margin: 7px 0;

      @media screen and (min-width: 600px) {
        margin: 0 0 16px 0;
      }
    }

    p.terms {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 140%;
      font-family: $fontLight;
      padding: 0 25px;

      @media screen and (min-width: 600px) {
        font-size: 16px;
        line-height: 140%;
      }
    }
  }
}
</style>
